import { useState, useEffect } from "react";
import { FaUserEdit } from "react-icons/fa";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, MultiSelect } from "@mantine/core";
import useApi from "../hooks/useApi";
import constants from "../constants/constants";
import "../css/EventTags.css";
import { useTranslation } from "react-i18next";

interface AddUsersToEventProps {
  users: models.UserProps[];
  event: models.EventProps;
}

export default function AddUsersToEvent({
  users,
  event,
}: AddUsersToEventProps) {
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    event?.assignedUsers ?? []
  );
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();

  const { callApi, loading } = useApi(
    `${constants.API_BASE_URL}${constants.ADD_USERS_TO_EVENT_ENDPOINT}`
  );

  useEffect(() => {
    setSelectedUsers(event?.assignedUsers ?? []);
  }, [event.assignedUsers]);

  const userNames = users.map((user) => ({
    value: user.uid,
    label: user.name,
  }));

  const handleSave = () => {
    const data = {
      eventId: event.id,
      users: selectedUsers,
    };
    callApi("POST", data);
    close();
  };

  return (
    <div>
      <FaUserEdit onClick={open} className="tag-icon-container" />
      <Modal opened={opened} onClose={close} title="יחצנים של האירוע" centered>
        <MultiSelect
          size="md"
          data={userNames}
          placeholder="בחר יחצנים לאירוע"
          value={selectedUsers}
          onChange={setSelectedUsers}
        />
        <Button
          style={{ marginTop: 10 }}
          color="black"
          onClick={handleSave}
          disabled={loading}
        >
          {t("saveUsersButton")}
        </Button>
      </Modal>
    </div>
  );
}
