import "../css/Login.css";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import { Button, Title } from "@mantine/core";
import { TextInput } from "@mantine/core";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface FormValues {
  email: string;
  password: string;
}

export default function ForgotPassword() {
  const loginForm = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const { resetPassword, authError, setAuthError } = useAuth();
  const methods = useForm<FormValues>();
  const { register, handleSubmit, formState } = methods;
  const { t } = useTranslation();

  const onSubmit = async (data: FormValues) => {
    try {
      await resetPassword(data.email);
      navigate("/login");
    } catch (error: any) {
      console.error("Password reset failed:", error.message);
    }
  };

  const moveToLoginPage = () => {
    navigate("/login");
    setAuthError(null);
  };

  return (
    <div className="login-container">
      <FormProvider {...methods}>
        <form
          ref={loginForm}
          onSubmit={handleSubmit(onSubmit)}
          className="login-form-container"
        >
          <Title>{t("forgotPasswordTitle")}</Title>
          <TextInput
            label={t("emailLabel")}
            placeholder={t("emailPlaceholder")}
            required
            mt="md"
            {...register("email", {
              required: t("emailRequired"),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t("emailPattern"),
              },
            })}
            error={formState.errors.email?.message}
          />
          <div>
            {authError && (
              <div style={{ color: "red", marginTop: "10px" }}>{authError}</div>
            )}
            <Button style={{ marginTop: "10px" }} type="submit">
              {t("resetPasswordButton")}
            </Button>
            <Button style={{ marginTop: "10px" }} onClick={moveToLoginPage}>
              {t("moveToLoginPage")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
