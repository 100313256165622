import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_ENCRYPTION_KEY as string;

export const encryptData = (data: any): string => {
  const stringData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(stringData, SECRET_KEY).toString();
};

export const decryptData = (cipherText: string): any | null => {
  try {
    if (
      !cipherText ||
      typeof cipherText !== "string" ||
      !cipherText.startsWith("U2Fsd")
    ) {
      return null; // Return null if the data is not encrypted or not valid
    }

    const bytes = CryptoJS.AES.decrypt(cipherText, SECRET_KEY);
    const decryptedString = bytes.toString(CryptoJS.enc.Utf8);

    if (!decryptedString) {
      throw new Error("Decryption failed");
    }

    // Parse as JSON if the decrypted string is valid
    return JSON.parse(decryptedString);
  } catch (error) {
    console.error("Decryption failed: ", error);
    return null;
  }
};
