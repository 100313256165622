import { Accordion, Text, Title } from "@mantine/core";

export default function Explanation() {
  return (
    <div
      style={{
        margin: "20px 0",
        padding: "15px",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        border: "1px solid #d1cfcf",
      }}
    >
      <Title order={2} style={{ marginBottom: "15px" }}>
        שאלות נפוצות
      </Title>
      <Accordion>
        <Accordion.Item value="overview">
          <Accordion.Control>הסבר כללי</Accordion.Control>
          <Accordion.Panel>
            <Text>
              דף זה מיועד לשימוש בממשק יחצנים, כולל התמצאות בטבלה ושימוש
              באפשרויות השונות המוצעות.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="editPersonalUrl">
          <Accordion.Control>עריכת קישור אישי</Accordion.Control>
          <Accordion.Panel>
            <Text>
              כדי לערוך את ההקישור האישי המקושר לאירוע, יש ללחוץ על אייקון
              העיפרון, להזין את הכתובת החדשה ולשמור את השינויים.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="deleteEvent">
          <Accordion.Control>מחיקת אירוע</Accordion.Control>
          <Accordion.Panel>
            <Text>
              כדי להסיר אירוע מהרשימה, יש ללחוץ על אייקון המחיקה ולאשר את הפעולה
              בחלון האישור שיופיע.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="copyLink">
          <Accordion.Control>העתקת קישור</Accordion.Control>
          <Accordion.Panel>
            <Text>
              כדי להעתיק את קישור האירוע, יש ללחוץ על אייקון ההעתקה ליד האירוע.
              הודעה תופיע לאישור שהקישור הועתק בהצלחה.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="cantCopyLink">
          <Accordion.Control>
            למה אני לא יכול להעתיק את הקישור של האירוע?
          </Accordion.Control>
          <Accordion.Panel>
            <Text>
              ייתכן שלא ניתן להעתיק את הקישור של האירוע מכיוון שהקישור האישי לא
              הוזן עדיין עבור אותו אירוע. ודא כי הוזן קישור אישי לאירוע על ידי
              לחיצה על אייקון העיפרון והוספת הכתובת.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="whyEventInRed">
          <Accordion.Control>למה האירוע מופיע באדום?</Accordion.Control>
          <Accordion.Panel>
            <Text>
              אירוע שמופיע באדום מציין כי לא הוזן קישור אישי עבור אותו אירוע.
              כדי לתקן זאת, יש ללחוץ על אייקון העיפרון ולהוסיף את הקישור הנדרש.
              ברגע שיוזן קישור אישי, האירוע לא יופיע יותר באדום.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
        <Accordion.Item value="personalLinkTitle">
          <Accordion.Control>מה זה הקישור בכותרת?</Accordion.Control>
          <Accordion.Panel>
            <Text>
              הקישור בכותרת הוא קישור שמוביל לדף הראשי באתר עם כל האירועים. כאשר
              משתמש נכנס דרך קישור זה ובוחר באירוע ספציפי, הוא ינותב אוטומטית לקישור
              שהגדרתם עבור אותו אירוע. כך, אתם יכולים לשתף קישור אחד מרכזי
              ולהבטיח שכל אחד מהאירועים שלכם יקבל את הקישור הייחודי שהגדרתם
              עבורו.
            </Text>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
