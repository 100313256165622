import FacebookButton from "../components/socialMedia/FacebookButton";
import InstagramButton from "../components/socialMedia/InstagramButton";
import WhatsAppButton from "../components/socialMedia/WhatsAppButton";
import { Title, Text } from "@mantine/core";
import "../css/SocialMediaSection.css";
import whiteLogo2 from "../assets/logos/whiteLogo2.png";

export default function SocialMediaSection() {
  return (
    <div className="social-media-container">
      <Title className="social-media-title">הצטרפו לקהילה</Title>
      <Text className="social-media-text">
        לחיצה אחת בלבד ומיד אתם חלק מקהילת המסיבות הגדולה והפעילה ביותר בישראל!
        הצטרפו אלינו ברשתות החברתיות וקבלו גישה בלעדית להטבות ולפסטיבלים הכי
        חמים בעיר. בין אם אתם מחפשים את ההפקות המדוברות ביותר, אירועים VIP, או
        רק רוצים להתעדכן בכל מה שקורה בעולם חיי הלילה – אנחנו כאן בשבילכם.
      </Text>
      <div className="social-media-buttons">
        <FacebookButton />
        <InstagramButton />
        <WhatsAppButton />
      </div>
      <p style={{ color: "white", fontSize: "20px" }}>
        לפניות ובירורים: enter.tickets.il@gmail.com
      </p>
      <img src={whiteLogo2} alt="searchIcon" className="search-image" />
    </div>
  );
}
