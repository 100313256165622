import { useState, useEffect } from "react";
import { Button, TextInput } from "@mantine/core";
import Alert from "../../../components/ui/Alert";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import constants from "../../../constants/constants";
import useApi from "../../../hooks/useApi";
import { MultiSelect } from "@mantine/core";
import { ClipLoader } from "react-spinners";
import DateSelector from "../../../components/ui/DateSelector";
import dayjs, { Dayjs } from "dayjs";
import "../../../css/admin/AddEvent.css";
import {
  eventCategories,
  timeCategories,
  areaCategories,
} from "../../../utils/categories";
import { useAuth } from "../../../utils/AuthContext";

interface AlertProps {
  title: string;
  message: string;
  color: string;
  icon: boolean;
}

export default function AddEvent({
  loadingDb,
  customCategories,
  setEvents,
}: any) {
  const { t } = useTranslation();
  const [url, setUrl] = useState<string>("");
  const [alert, setAlert] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [date, setDate] = useState<Dayjs | null>(dayjs(new Date()));

  const [alertData, setAlertData] = useState<AlertProps>({
    title: "",
    message: "",
    color: "",
    icon: false,
  });

  const {
    data,
    loading: apiLoading,
    callApi: callApiEvent,
  } = useApi(`${constants.API_BASE_URL}${constants.FIND_EVENT_ENDPOINT}`);

  const { getIdToken } = useAuth();

  useEffect(() => {
    if (data === constants.EVENT_EXISTS) {
      setAlertData({
        title: t("eventAlreadyExistTitle"),
        message: t("eventAlreadyExist"),
        color: "red",
        icon: false,
      });
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 4000);
    } else if (data) {
      setAlertData({
        title: t("eventAddedTitle"),
        message: t("eventAddedMessage"),
        color: "green",
        icon: true,
      });
      setAlert(true);

      setEvents((prevEvents: any) => [...prevEvents, data]);
      setUrl("");
      setTimeout(() => {
        setAlert(false);
      }, 4000);
    }
  }, [data, setAlert, t, setEvents]);

  const addEvent = async () => {
    const token = await getIdToken();
    if (!url) return;
    callApiEvent("POST", {
      url,
      id: uuidv4(),
      categories: categories,
      date: date,
      authorization: token,
    });
  };

  return (
    <div className="add-event-container">
      {alert && <Alert alertData={alertData} />}
      <h2 style={{ margin: "0" }}>{t("addEventsTitle")}</h2>
      <div className="add-event-form">
        <TextInput
          size="md"
          placeholder={t("addEventPlaceholder")}
          mt="md"
          onChange={(event) => setUrl(event.target.value)}
        />
        <DateSelector date={date} setDate={setDate} />
        <h3 style={{ margin: "0" }}>{t("addCategoriesTitle")}</h3>
        <MultiSelect
          data={[
            ...eventCategories,
            ...timeCategories,
            ...customCategories,
            ...areaCategories,
          ]}
          value={categories}
          onChange={setCategories}
          placeholder="בחר קטגוריות"
          size="md"
          clearable
          hidePickedOptions
        />
        <div className="add-event-button-container">
          <Button
            color="black"
            onClick={addEvent}
            type="submit"
            disabled={apiLoading}
          >
            {t("addEventButton")}
          </Button>
          {(apiLoading || loadingDb) && <ClipLoader size={30} />}
        </div>
      </div>
    </div>
  );
}
