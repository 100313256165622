import { TextInput, Button, Chip } from "@mantine/core";
import { useState } from "react";
import whiteLogo from "../../assets/logos/whiteLogo.png";
import "../../css/home/EventSearchBar.css";
import { useTranslation } from "react-i18next";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function EventSearchBar() {
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  // Example popular searches
  const popularSearches = ["טכנו", "Psytrix", "Jimmy who", "תל אביב"];

  const handleSearch = (searchValue: string) => {
    if (searchValue.trim() === "") return;
    console.log("Searching for:", searchValue);
    navigate(`/search/${encodeURIComponent(searchValue)}/${id}`);
  };

  return (
    <div className="search-bar-container">
      <img src={whiteLogo} alt="searchIcon" className="search-image" />
      <div className="search-bar-inner">
        <div className="search-input-container">
          <TextInput
            size="lg"
            placeholder={t("searchPlaceholder")}
            value={searchValue}
            onChange={(event) => setSearchValue(event.currentTarget.value)}
            className="search-input"
          />
        </div>
        <Button
          onClick={() => handleSearch(searchValue)}
          size="lg"
          className="search-button"
        >
          <FaMagnifyingGlass />
        </Button>
      </div>

      {/* Popular Searches */}
      <Chip.Group>
        <div className="popular-search-chips">
          {popularSearches.map((term) => (
            <Chip
              mt="md"
              key={term}
              value={term}
              onClick={() => setSearchValue(term)}
            >
              {term}
            </Chip>
          ))}
        </div>
      </Chip.Group>
    </div>
  );
}
