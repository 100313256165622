import { useState } from "react";
import EventCube from "./ui/EventCube";
import "../css/home/EventCubeList.css";
import { Button, Skeleton } from "@mantine/core";
import { useTranslation } from "react-i18next";

interface EventCubeListProps {
  events: models.EventProps[];
  loading: boolean; // New prop to handle the loading state
}

export default function EventCubeList({ events, loading }: EventCubeListProps) {
  const { t } = useTranslation();

  const cubesPerRow = 6;
  const rowsToShowInitially = 2;
  const [visibleCubes, setVisibleCubes] = useState(
    cubesPerRow * rowsToShowInitially
  );

  const loadMoreEvents = () => {
    setVisibleCubes(visibleCubes + cubesPerRow * 2);
  };

  const renderSkeletons = () => {
    return Array.from({ length: visibleCubes }, (_, index) => (
      <Skeleton key={index} height={300} width="100%" radius="md" />
    ));
  };

  const noEvents = !loading && events.length === 0;

  return (
    <div>
      <div className="event-grid">
        {loading
          ? renderSkeletons()
          : events
              .slice(0, visibleCubes)
              .map((event: models.EventProps) => (
                <EventCube key={event.eventUrl} event={event} />
              ))}
      </div>

      {!loading && visibleCubes < events.length && events.length > 0 && (
        <div className="load-more-container">
          <Button
            size="lg"
            color="dark"
            className="load-more-button"
            onClick={loadMoreEvents}
          >
            {t("loadMoreEvents")}
          </Button>
        </div>
      )}

      {noEvents && <h2>לא נמצאו אירועים מתאימים</h2>}
    </div>
  );
}
