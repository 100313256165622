import Navbar from "../Navbar";
import Footer from "../Footer";
import "../../css/accessibilityStatement/AccessibilityStatement.css";

export default function PrivacyPolicy() {

  return (
    <div>
      <Navbar />
      <div className="accessibility-policy-container">
        <h1>הצהרת נגישות</h1>
        <p>
          כאתר אינטרנט, אנחנו מבינים את החשיבות של נגישות. האינטרנט הוא כלי
          חיוני בחיי היומיום, ואנחנו רוצים לוודא שכל המשתמשים שלנו יכולים להשתמש
          בו בצורה שווה. אנחנו מחויבים להנגיש את האתר שלנו על פי התקנים
          המקצועיים הגבוהים ביותר, כדי להבטיח חווית משתמש מיטבית לכל אחד. אנחנו
          מאמינים בשוויון ובכבוד לכל אדם. חלק בלתי נפרד מהשוויון הוא היכולת של
          כל אחד להשתמש באינטרנט באופן עצמאי. לכן, אנחנו משקיעים משאבים רבים
          בהנגשת האתר שלנו, כדי לאפשר לכל אדם, ללא קשר ליכולותיו, לגשת למידע
          ולשירותים שאנחנו מציעים. אנו שואפים לדבוק ככל האפשר בהמלצות התקן
          הישראלי לנגישות תכנים באינטרנט ובמסמך WCAG2.1 הבינלאומי.
        </p>
        <div className="boxed-section">
          <h3>כיצד עובדת ההנגשה?</h3>
          <p>
            באתר מוצב תפריט הנגשה של חברת הנגשת אתרים. לחיצה על התפריט מאפשרת
            פתיחת כפתורי ההנגשה. לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.
            התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera
            בכפוף לתנאי היצרן. אפשרויות הנגישות בתפריט:
          </p>
          <ul>
            <li>התאמה לניווט מקלדת- מתן אפשרות לניווט ע"י מקלדת</li>
            <li>
              התאמה לקורא מסך – התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA ,
              JAWS
            </li>
            <li>
              חסימת הבהובים – עצירת אלמנטים נעים וחסימת הבהובים * הגדלת פונט
              האתר ל-4 גדלים שונים
            </li>
            <li>התאמות ניגודיות – שינוי ניגודיות צבעי האתר</li>
            <li>התאמת האתר לעיוורי צבעים</li>
            <li>שינוי הפונט לקריא יותר</li>
            <li>הגדלת הסמן ושינוי צבעו לשחור או לבן </li>
            <li>הגדלת התצוגה לכ-200%</li>
            <li>הדגשת קישורים באתר</li>
            <li>הדגשת כותרות באתר</li>
            <li>הצגת תיאור אלטרנטיבי לתמונות</li>
            <li>הצהרת נגישות</li>
            <li>שליחת משוב נגישות</li>
          </ul>
        </div>
        <h3>enter-tickets הוקמה על מנת להנגיש</h3>
        <p>
          חובבי חיי הלילה בישראל ובעולם מתמודדים עם אתגר משמעותי: כיצד למצוא את
          האירועים המושלמים עבורם? עם מגוון עצום של מועדונים, פסטיבלים ואירועים
          מוזיקליים, קשה לעקוב אחר כל מה שקורה ולמצוא את האירועים המתאימים
          לטעמים האישיים של כל אחד. אפליקציית enter-tickets מגיעה כדי לפתור את
          הבעיה הזו. הפלטפורמה החדשנית הזו מציעה פתרון כוללני ונוח לחובבי מוזיקה
          ולילה, ומאפשרת להם לגלות בקלות את כל האירועים המרתקים ביותר בישראל
          ובעולם ושלל מסיבות "קצה". היא הפלטפורמה המושלמת עבור כל מי שרוצה
          להישאר מעודכן בכל מה שקורה בעולם המוזיקה והלילה. בין אם אתם חובבי
          מוזיקה אלקטרונית, רוק, היפ הופ או כל ז'אנר אחר, איירדרופ תספק לכם את
          כל המידע שאתם צריכים כדי לתכנן את הלילות שלכם בצורה הטובה ביותר.
        </p>
        <div className="boxed-section">
          <h3>במה אנחנו מנגישים את חיי הלילה?</h3>
          <ul>
            <li>
              מסך אחד, כל האירועים: פלטפורמת enter-tickets מאגדת את כל האירועים
              המוזיקליים הרלוונטיים למחלקת אחת נוחה, כך שתוכלו למצוא בקלות את כל
              מה שמעניין אתכם.
            </li>
            <li>
              סינון חכם: באמצעות מערכת סינון מתקדמת, תוכלו להתאים את החיפוש שלכם
              לפי הז'אנרים המוזיקליים האהובים עליכם, האיזור הגאוגרפי והלוקיישן
              המועדף.
            </li>
            <li>
              מידע מלא על כל אירוע: לכל אירוע תמצאו פרטים מלאים, כולל תאריך,
              שעה, לוקיישן, ליין-אפ של האמנים, מידע על הכניסה והרבה יותר.
            </li>
            <li>
              רכישה נוחה של כרטיסים: ישירות מתוך האפליקציה תוכלו להגיע לאתרי
              רכישה מאובטחים מבלי לקחת סיכון ברכישה מאתרים לא מאומתים.
            </li>
            <li>
              עדכונים בזמן אמת: הפלטפורמה מעדכנת אתכם באופן שוטף על אירועים
              חדשים, שינויים בלוקיישנים וביטולים, כך שתמיד תהיו מעודכנים.
            </li>
            <li>
              קהילה תוססת: הצטרפו לקהילה של חובבי מוזיקה ולילה, שתפו אירועים,
              גלו המלצות מחברים ועקבו אחרי האמנים האהובים עליכם.
            </li>
          </ul>
        </div>
        <div className="boxed-section">
          <h3>יתרונות נוספים</h3>
          <ul>
            <li>
              חיסכון בזמן: במקום לבזבז שעות על חיפוש באינטרנט, תוכלו למצוא את כל
              האירועים שאתם מחפשים במקום אחד.
            </li>
            <li>
              גילוי אירועים חדשים: האפליקציה תציג לכם אירועים שלא הכרתם קודם
              לכן, ותעזור לכם לגלות ז'אנרים חדשים ואמנים חדשים.
            </li>
            <li>
              החלטות מושכלות: עם כל המידע הרלוונטי בהישג יד, תוכלו לקבל החלטות
              מושכלות לגבי האירועים שתרצו להשתתף בהם.
            </li>
            <li>
              חווית משתמש מעולה: ממשק המשתמש האינטואיטיבי והעיצוב המודרני הופכים
              את השימוש באתר לחוויה מהנה ופשוטה.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
}
