import { MdEdit } from "react-icons/md";
import { useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, TextInput } from "@mantine/core";
import "../css/EventTags.css";
import { useTranslation } from "react-i18next";
import useApi from "../hooks/useApi";
import constants from "../constants/constants";

interface EditPersonalUrlProps {
  currentUser: models.UserProps;
  eventId: string;
  updateUserEvents: (eventId: string, newUrl: string) => void;
}

export default function EditPersonalUrl({
  currentUser,
  eventId,
  updateUserEvents,
}: EditPersonalUrlProps) {
  const [url, setUrl] = useState<string>(currentUser.userEvents[eventId]);
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation();

  const { callApi, loading } = useApi(
    `${constants.API_BASE_URL}${constants.UPDATE_USER_PERSONAL_URL_ENDPOINT}`
  );

  const handleSave = () => {
    const data = {
      eventId: eventId,
      personalUrl: url,
      userId: currentUser.uid,
    };
    callApi("POST", data);
    updateUserEvents(eventId, url);
    close();
  };

  return (
    <div>
      <MdEdit onClick={open} className="tag-icon-container" />
      <Modal
        opened={opened}
        onClose={close}
        title="שינוי קישור של האירוע"
        centered
      >
        <TextInput
          size="lg"
          placeholder="הכנס את הקישור שלך"
          onChange={(e) => setUrl(e.target.value)}
          value={url}
        />
        <Button
          style={{ marginTop: 10 }}
          color="black"
          onClick={handleSave}
          disabled={loading}
        >
          {t("saveCustomUrl")}
        </Button>
      </Modal>
    </div>
  );
}
