import { FaInstagram } from "react-icons/fa";
import "../../css/socialMedia/InstagramButton.css";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import constants from "../../constants/constants";
import { useTranslation } from "react-i18next";

export default function InstagramButton() {
  const { t } = useTranslation();

  return (
    <Link to={constants.INSTAGRAM_URL} style={{ textDecoration: "none", display: "flex", justifyContent: "center" }}>
      <div className="instagram-container">
        <FaInstagram className="instagram-icon" />
        <Text className="instagram-text">{t("instagramButton")}</Text>
      </div>
    </Link>
  );
}
