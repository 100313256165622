import { useState } from "react";
import useApi from "../../../hooks/useApi";
import { v4 as uuidv4 } from "uuid";
import { Button, TextInput } from "@mantine/core";
import constants from "../../../constants/constants";
import { FaTrash } from "react-icons/fa"; // Import the trash icon
import "../../../css/admin/CustomCategories.css";

interface CustomCategoriesProps {
  customCategories: string[];
  setCustomCategories: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function CustomCategories({
  customCategories,
  setCustomCategories,
}: CustomCategoriesProps) {
  const [customCategoryInput, setCustomCategoryInput] = useState("");

  const { callApi: callApiAddCategory } = useApi(
    `${constants.API_BASE_URL}${constants.ADD_CUSTOM_CATEGORY_ENDPOINT}`
  );

  const { callApi: callApiDeleteCategory } = useApi(
    `${constants.API_BASE_URL}${constants.DELETE_CUSTOM_CATEGORY_ENDPOINT}`
  );

  const addCategory = () => {
    if (customCategoryInput.trim() === "") return;

    if (customCategories.some((category) => category === customCategoryInput)) {
      alert("הסינון כבר קיים");
      return;
    }

    if (customCategories.length >= 2) {
      alert("אפשר להוסיף עד 2 סינונים מותאמים");
      return;
    }

    callApiAddCategory("POST", {
      category: customCategoryInput,
      uuid: uuidv4(),
    });
    setCustomCategories([...customCategories, customCategoryInput]);
    setCustomCategoryInput("");
  };

  const deleteCustomCategory = (value: string) => {
    callApiDeleteCategory("POST", { category: value, uuid: uuidv4() });
    setCustomCategories(
      customCategories.filter((category) => category !== value)
    );
  };

  return (
    <div className="custom-categories-container">
      <div className="cc-title">
        <h2>הוסף קטגוריה חדשה</h2>
      </div>
      <div className="custom-categories-form">
        <TextInput
          value={customCategoryInput}
          onChange={(event) =>
            setCustomCategoryInput(event.currentTarget.value)
          }
          placeholder="הזן סינון חדש"
        />
        <Button color="black" onClick={addCategory} className="cc-add-button">
          הוסף קטגוריה
        </Button>
      </div>
      <h3 style={{ margin: "0" }}>קטגוריות מותאמות</h3>
      {customCategories.length === 0 ? (
        <p>אין קטגוריות מותאמות</p>
      ) : (
        <ul className="cc-category-list">
          {customCategories.map((category) => (
            <li key={category} className="cc-category-item">
              <span>{category}</span>
              <Button
                color="black"
                onClick={() => deleteCustomCategory(category)}
                size="xs"
                leftSection={<FaTrash />}
              >
                מחק
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
