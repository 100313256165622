import { FaFacebook } from "react-icons/fa";
import "../../css/socialMedia/FacebookButton.css";
import { Link } from "react-router-dom";
import constants from "../../constants/constants";
import { Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

export default function FacebookButton() {
  const { t } = useTranslation();

  return (
    <Link
      to={constants.FACEBOOK_URL}
      style={{
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="facebook-container">
        <FaFacebook className="facebook-icon" />
        <Text className="facebook-text">{t("facebookButton")}</Text>
      </div>
    </Link>
  );
}
