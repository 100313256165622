import "../../css/event/DiscountBanner.css";
import { Link } from "react-router-dom";
import constants from "../../constants/constants";

export default function DiscountBanner({ imageUrl }: { imageUrl: string }) {
  return (
    <div className="discount-banner">
      {/* The image with blur is in the background overlay */}
      <div
        className="discount-background"
        style={{ backgroundImage: `url(${imageUrl})` }}
      ></div>
      <Link to={constants.WHATSAPP_GROUP_URL} style={{ textDecoration: "none" }}>
        <div className="discount-overlay"></div>
        <p className="discount-text">?רוצים לקבל הנחות לאירועים שלנו</p>
      </Link>
    </div>
  );
}
