import Navbar from "../Navbar";
import Footer from "../Footer";
import "../../css/privacyPolicy/PrivacyPolicy.css";
export default function PrivacyPolicy() {
  return (
    <div>
      <Navbar />
      <div className="privacy-policy-container">
        <h1>מדיניות פרטיות</h1>
        <p>
          ב-e-ticket.co.il, פרטיות המשתמשים חשובה לנו מאוד. מדיניות פרטיות זו
          מתארת את האופן שבו אנו אוספים, משתמשים, מגלים ומגנים על המידע האישי
          שלך כאשר אתה מבקר באתר שלנו או משתמש בשירותים שלנו.
        </p>
        <h3>מי אנחנו?</h3>
        <p>
          האתר (e-ticket.co.il) נועד לשמש אתכם באיתור מסיבות ופסטיבלים ברחבי
          הרשת כאשר אנחנו מאמתים את האירועים ואת דפי המכירה שלהם ומסננים לכם רק
          אירועים אמינים ואתרי רכישה מאושרים על פי חוק.
        </p>
        <h3>תגובות</h3>
        <p>
          כאשר המבקרים משאירים תגובות באתר אנו אוספים את הנתונים המוצגים בטופס
          התגובה, ובנוסף גם את כתובת ה-IP של המבקר, ואת מחרוזת ה-user agent של
          הדפדפן שלו כדי לסייע בזיהוי תגובות זבל. יתכן ונעביר מחרוזת אנונימית
          שנוצרה מכתובת הדואר האלקטרוני שלך (הנקראת גם hash) לשירות Gravatar כדי
          לראות אם הנך חבר/ה בשירות. מדיניות הפרטיות של שירות Gravatar זמינה
          כאן: https://automattic.com/privacy/. לאחר אישור התגובה שלך, תמונת
          הפרופיל שלך גלויה לציבור בהקשר של התגובה שלך.
        </p>
        <h3>מדיה</h3>
        <p>
          בהעלאה של תמונות לאתר, מומלץ להימנע מהעלאת תמונות עם נתוני מיקום
          מוטבעים (EXIF GPS). המבקרים באתר יכולים להוריד ולחלץ את כל נתוני מיקום
          מהתמונות באתר.
        </p>
        <h3>עוגיות</h3>
        <p>
          בכתיבת תגובה באתר שלנו, באפשרותך להחליט אם לאפשר לנו לשמור את השם שלך,
          כתובת האימייל שלך וכתובת האתר שלך בקבצי עוגיות (cookies). השמירה תתבצע
          לנוחיותך, על מנת שלא יהיה צורך למלא את הפרטים שלך שוב בכתיבת תגובה
          נוספת. קבצי העוגיות ישמרו לשנה. אם אתה מבקר בעמוד ההתחברות של האתר,
          נגדיר קובץ עוגיה זמני על מנת לקבוע האם הדפדפן שלך מקבל קבצי עוגיות.
          קובץ עוגיה זה אינו מכיל נתונים אישיים והוא נמחק בעת סגירת הדפדפן. כאשר
          תתחבר, אנחנו גם נגדיר מספר 'עוגיות' על מנת לשמור את פרטי ההתחברות שלך
          ואת בחירות התצוגה שלך. עוגיות התחברות תקפות ליומיים, ועוגיות אפשרויות
          מסך תקפות לשנה. אם תבחר באפשרות "זכור אותי", פרטי ההתחברות שלך יהיו
          תקפים למשך שבועיים. אם תתנתק מהחשבון שלך, עוגיות ההתחברות יימחקו. אם
          אתה עורך או מפרסם מאמר, קובץ 'עוגיה' נוסף יישמר בדפדפן שלך. קובץ
          'עוגיה' זה אינו כולל נתונים אישיים ופשוט מציין את מזהה הפוסט של המאמר.
          הוא יפוג לאחר יום אחד.
        </p>
        <h3>תוכן מוטמע מאתרים אחרים</h3>
        <p>
          כתבות או פוסטים באתר זה עשויים לכלול תוכן מוטבע (לדוגמה, קטעי וידאו,
          תמונות, מאמרים, וכו'). תוכן מוטבע מאתרי אינטרנט אחרים דינו כביקור
          הקורא באתרי האינטרנט מהם מוטבע התוכן. אתרים אלו עשויים לאסוף נתונים
          אודותיך, להשתמש בקבצי 'עוגיות', להטמיע מעקב של צד שלישי נוסף, ולנטר את
          האינטראקציה שלך עם תוכן מוטמע זה, לרבות מעקב אחר האינטראקציה שלך עם
          התוכן המוטמע, אם יש לך חשבון ואתה מחובר לאתר זה.
        </p>
        <h3>עם מי אנו חולקים את המידע שלך</h3>
        <p>
          אם תבקשו לאפס את הסיסמה, כתובת ה-IP שלכם תיכלל באימייל לאיפוס שיישלח.
        </p>
        <h3>משך הזמן בו נשמור את המידע שלך</h3>
        <p>
          במידה ותגיב/י על תוכן באתר, התגובה והנתונים אודותיה יישמרו ללא הגבלת
          זמן, כדי שנוכל לזהות ולאשר את כל התגובות העוקבות באופן אוטומטי. עבור
          משתמשים רשומים באתר (במידה ויש כאלה) אנו מאחסנים גם את המידע האישי שהם
          מספקים בפרופיל המשתמש שלהם. כל המשתמשים יכולים לראות, לערוך או למחוק
          את המידע האישי שלהם בכל עת (פרט לשם המשתמש אותו לא ניתן לשנות). גם
          מנהלי האתר יכולים לראות ולערוך מידע זה.
        </p>
        <h3>אילו זכויות יש לך על המידע שלך</h3>
        <p>
          אם יש לך חשבון באתר זה, או שהשארת תגובות באתר, באפשרותך לבקש לקבל קובץ
          של הנתונים האישיים שאנו מחזיקים לגביך, כולל כל הנתונים שסיפקת לנו.
          באפשרותך גם לבקש שנמחק כל מידע אישי שאנו מחזיקים לגביך. הדבר אינו כולל
          נתונים שאנו מחויבים לשמור למטרות מנהליות, משפטיות או ביטחוניות.
        </p>
        <h3>לאן נשלח המידע שלך</h3>
        <p>
          תגובות מבקרים עלולות להיבדק על ידי שירות אוטומטי למניעת תגובות זבל.
        </p>
      </div>
      <Footer />
    </div>
  );
}
