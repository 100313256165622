import { FaWhatsapp } from "react-icons/fa";
import "../../css/socialMedia/WhatsAppButton.css";
import { Link } from "react-router-dom";
import { Text } from "@mantine/core";
import constants from "../../constants/constants";
import { useTranslation } from "react-i18next";

export default function WhatsAppButton() {
  const { t } = useTranslation();

  return (
      <Link
        to={constants.WHATSAPP_GROUP_URL}
        style={{
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="whatsapp-container">
          <FaWhatsapp className="whatsapp-icon" />
          <Text className="whatsapp-text">{t("whatsappButton")}</Text>
        </div>
      </Link>
  );
}
