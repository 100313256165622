import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function useStickyFooter() {
  const [isSticky, setIsSticky] = useState(false);
  const [isAboveFooter, setIsAboveFooter] = useState(true);
  const location = useLocation(); // Use this to detect route changes

  // Scroll to top when route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // Handle scroll and footer positioning logic
  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector("footer");
      const stickyFooter = document.querySelector(".sticky-footer");

      // Toggle sticky based on scroll position
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }

      // Detect if footer is in view to position the sticky footer correctly
      if (footer && stickyFooter) {
        const footerTop = footer.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (footerTop <= windowHeight) {
          setIsAboveFooter(false); // Footer is visible, move sticky footer on top
        } else {
          setIsAboveFooter(true); // Footer is not visible yet, keep sticky footer fixed
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return { isSticky, isAboveFooter };
}
