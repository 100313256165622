import { useState, useEffect } from "react";
import useApi from "./useApi";
import constants from "../constants/constants";
import { encryptData, decryptData } from "../utils/cryptoUtils"; // Import encryption utilities

export const useDataFetcher = () => {
  const [events, setEvents] = useState<models.EventProps[]>([]);
  const [users, setUsers] = useState<models.UserProps[]>([]);
  const [utils, setUtils] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    data: db,
    callApi: callApiDb,
    loading: loadingDb,
  } = useApi(`${constants.API_BASE_URL}${constants.FIREBASE_DB_ENDPOINT}`);

  const { data: dataUuid, callApi: callApiUuid } = useApi(
    `${constants.API_BASE_URL}${constants.GET_UUID_ENDPOINT}`
  );

  const storedUtilsEncrypted = localStorage.getItem(constants.UTILS_COLLECTION);
  const parsedUtils = storedUtilsEncrypted
    ? decryptData(storedUtilsEncrypted)
    : null;

  const storedEventsEncrypted = localStorage.getItem(
    constants.EVENTS_COLLECTION
  );
  const parsedEvents = storedEventsEncrypted
    ? decryptData(storedEventsEncrypted)
    : null;

  const storedUsersEncrypted = localStorage.getItem(constants.USERS_COLLECTION);
  const parsedUsers = storedUsersEncrypted
    ? decryptData(storedUsersEncrypted)
    : null;

  const saveToLocalStorage = (key: string, data: any) => {
    const encryptedData = encryptData(data);
    localStorage.setItem(key, encryptedData);
  };

  useEffect(() => {
    if (
      !parsedEvents ||
      !parsedUsers ||
      !parsedUtils ||
      (Array.isArray(parsedUtils) && parsedUtils.length === 0)
    ) {
      console.log("Fetching from Firebase");
      callApiDb("POST");
      return;
    }

    setEvents(parsedEvents);
    setUsers(parsedUsers);
    setUtils(parsedUtils);
    setLoading(false);

    callApiUuid("POST", { parsedUtils });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataUuid) {
      if (!dataUuid.users || !dataUuid.events) {
        if (parsedEvents) setEvents(parsedEvents);
        if (parsedUsers) setUsers(parsedUsers);
        if (parsedUtils) setUtils(parsedUtils);
      }
      if (dataUuid.users) {
        callApiDb("POST", { collection: constants.USERS_COLLECTION });
      }
      if (dataUuid.events) {
        callApiDb("POST", { collection: constants.EVENTS_COLLECTION });
      }
      if (dataUuid.categories) {
        callApiDb("POST", { collection: constants.UTILS_COLLECTION });
      }
    }
    // eslint-disable-next-line
  }, [dataUuid]);

  useEffect(() => {
    if (db) {
      if (db.events) {
        let orderedEvents = [];

        if (db.utils && db.utils[1] && db.utils[1].order) {
          const eventsMap = new Map();
          db.events.forEach((event: { id: string }) => {
            eventsMap.set(event.id, event);
          });

          orderedEvents = db.utils[1].order
            .map((id: string) => eventsMap.get(id))
            .filter(Boolean);
        }

        if (orderedEvents.length === 0) {
          console.warn(
            "Ordered events are empty, using events as they are from Firebase"
          );
          orderedEvents = db.events;
        }

        saveToLocalStorage(constants.EVENTS_COLLECTION, orderedEvents);
        setEvents(orderedEvents);
      }

      if (db.users) {
        saveToLocalStorage(constants.USERS_COLLECTION, db.users);
        setUsers(db.users);
      }

      if (db.utils) {
        saveToLocalStorage(constants.UTILS_COLLECTION, db.utils);
        setUtils(db.utils);
      }
      setLoading(false);
    }
  }, [db]);

  return {
    setEvents,
    events,
    users,
    setUsers,
    utils,
    loadingDb,
    callApiDb,
    loading,
  };
};
