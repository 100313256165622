import { useState, useEffect } from "react";
import EventsTable from "../../../tables/EventsTable";
import AdminTabs from "../../../tables/AdminTab";
import CarouselEventManager from "./CarouselEventManager";
import AddEvent from "./AddEvent";
import CustomCategories from "./CustomCategories";
import constants from "../../../constants/constants";
import { Button, Divider } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../utils/AuthContext";

interface AdminViewProps {
  events: any[];
  setEvents: any;
  users: any[];
  setUsers: any;
  utils: any[];
  callApiDb: any;
  loadingDb: boolean;
}

export default function AdminView({
  events,
  setEvents,
  users,
  setUsers,
  utils,
  callApiDb,
  loadingDb,
}: AdminViewProps) {
  const [tabs, setTabs] = useState<string | null>(constants.EVENTS_TAB);
  const [customCategories, setCustomCategories] = useState<any[]>([]);
  const { t } = useTranslation();
  const { logoutUser, user } = useAuth();

  const currentUser = users.find((u) => u.uid === user?.uid);

  useEffect(() => {
    if (utils && utils.length > 0 && utils[0]?.categories) {
      setCustomCategories(utils[0].categories);
    }
  }, [utils]);



  return (
    <div>
      <h1>{t("helloPromoter") + currentUser?.name}</h1>
      <div className="top-section">
        <div className="component-wrapper">
          <AddEvent
            callApiDb={callApiDb}
            loadingDb={loadingDb}
            customCategories={customCategories}
            setEvents={setEvents}
            events={events}
          />
        </div>
        <div className="component-wrapper">
          <CustomCategories
            customCategories={customCategories}
            setCustomCategories={setCustomCategories}
          />
        </div>
        <div className="component-wrapper">
          <CarouselEventManager eventsList={events} />
        </div>
      </div>

      <Divider size="xs" color="black" style={{ marginTop: "20px" }} />

      <div className="table-section">
        <AdminTabs tabs={tabs} setTabs={setTabs} />
        <EventsTable
          events={events}
          setEvents={setEvents}
          users={users}
          setUsers={setUsers}
        />
      </div>

      <div className="logout-button">
        <Button color="black" onClick={() => logoutUser()}>
          {t("logOutButton")}
        </Button>
      </div>
    </div>
  );
}
