import { Tabs, rem } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import constants from "../constants/constants";

interface TabsTableProps {
  tabs: string | null;
  setTabs: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function AdminTabs({ tabs, setTabs }: TabsTableProps) {
  const { t } = useTranslation();

  const iconStyle = { width: rem(12), height: rem(12) };

  return (
    <Tabs
      value={tabs}
      onChange={setTabs}
      color="gray"
      variant="outline"
      radius="lg"
      defaultValue="gallery"
    >
      <Tabs.List>
        <Tabs.Tab
          value={constants.EVENTS_TAB}
          leftSection={<IconPhoto style={iconStyle} />}
        >
          {t("eventsTab")}
        </Tabs.Tab>
      </Tabs.List>
    </Tabs>
  );
}
