import { BiParty } from "react-icons/bi";
import { FaCalendarAlt } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { GoStarFill } from "react-icons/go";
import { HiAdjustments } from "react-icons/hi";
import { GiFlyingShuriken } from "react-icons/gi";

// Event categories with React Icons
export const eventCategories = [
  {
    value: "",
    label: "הכל",
    icon: HiAdjustments, // Adjustments icon
  },
  {
    value: "מיינסטרים",
    label: "מיינסטרים",
    icon: BiParty, // Party icon
  },
  {
    value: "טראנס",
    label: "טראנס",
    icon: GoStarFill, // Star icon
  },
  {
    value: "טכנו",
    label: "טכנו",
    icon: GiFlyingShuriken, // Shuriken icon
  },
];

// Time categories with React Icons
export const timeCategories = [
  {
    value: "היום",
    label: "היום",
    icon: FaCalendarAlt, // Calendar icon
  },
  {
    value: "הסופש",
    label: "הסופש",
    icon: FaCalendarAlt, // Calendar icon
  },
];

// Area categories with React Icons
export const areaCategories = [
  {
    value: "צפון",
    label: "צפון",
    icon: IoLocationSharp, // Location icon
  },
  {
    value: "מרכז",
    label: "מרכז",
    icon: IoLocationSharp, // Location icon
  },
  {
    value: "דרום",
    label: "דרום",
    icon: IoLocationSharp, // Location icon
  },
];
