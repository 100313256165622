export function findPersonalUrl(
    event: models.EventProps,
    users: models.UserProps[],
    id?: string
  ): string {
    if (id) {
      // Find the user with the given id
      const user = users.find((user) => user.uid === id);
      if (user) {
        // Check if the user is assigned to the event
        if (event.assignedUsers && event.assignedUsers.includes(id)) {
          // Get the user's personal URL for this event
          const userEventUrl = user.userEvents?.[event.id];
          if (userEventUrl) {
            return userEventUrl;
          }
        }
      }
    }
    // Fallback to the event's default URL
    return event.eventUrl;
  }
  