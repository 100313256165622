import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Title, Skeleton } from "@mantine/core";
import "../../css/home/Carousel.css";
import { Link } from "react-router-dom";
import {
  Autoplay,
  EffectCoverflow,
  Navigation,
  Pagination,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useParams } from "react-router-dom";

export default function Carousel({
  events,
  loading,
}: {
  events: models.EventProps[];
  loading: boolean;
}) {
  const [carouselEvents, setCarouselEvents] = useState<models.EventProps[]>([]);
  const { id } = useParams();

  useEffect(() => {
    const filteredEvents = events.filter(
      (event: models.EventProps) => event.isCarrousel
    );
    setCarouselEvents(filteredEvents);
  }, [events]);

  const renderSkeletons = () => {
    return Array.from({ length: 4 }).map((_, index) => (
      <SwiperSlide key={index}>
        <div className="carousel-skeleton-wrapper">
          <div className="carousel-image-container">
            <Skeleton height={425} width={550} radius="md" />
          </div>
          <Skeleton
            height={30}
            width={300}
            mt={20}
            className="carousel-title-skeleton"
          />
        </div>
      </SwiperSlide>
    ));
  };

  return (
    <Swiper
      key={carouselEvents.length}
      effect={"coverflow"}
      grabCursor={true}
      centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      speed={3000}
      touchRatio={0.8}
      coverflowEffect={{
        rotate: 50,
        stretch: -50,
        depth: 200,
        modifier: 1,
        slideShadows: false,
      }}
      pagination={false}
      navigation={true}
      modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40,
        },
      }}
    >
      {loading ? (
        <>{renderSkeletons()}</> 
      ) : (
        carouselEvents.map((event: models.EventProps) => (
          <SwiperSlide key={event.eventUrl}>
            <Link
              to={`/event/${encodeURIComponent(event.eventTitle)}/${id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="carousel-image-container">
                <img
                  src={event.eventImage}
                  alt={event.eventTitle}
                  className="carousel-image"
                />
              </div>
              <Title className="carousel-caption" order={3}>
                {event.eventTitle}
              </Title>
              <Title className="carousel-caption-mini" order={3}>
                {event.eventDate}
              </Title>
            </Link>
          </SwiperSlide>
        ))
      )}
    </Swiper>
  );
}
