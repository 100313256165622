import { useState, useEffect } from "react";
import { Button, Text, Box, Title } from "@mantine/core";
import { useAuth } from "../../../utils/AuthContext";
import { IoCopyOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import PromoterTable from "../../../tables/PromoterTable";
import constants from "../../../constants/constants";
import Explanation from "./Explanation";

interface PromoterViewProps {
  events: models.EventProps[];
  users: models.UserProps[];
  setUsers: React.Dispatch<React.SetStateAction<models.UserProps[]>>;
  setEvents: React.Dispatch<React.SetStateAction<models.EventProps[]>>;
}

export default function PromotersView({
  events,
  users,
  setUsers,
  setEvents,
}: PromoterViewProps) {
  const [userEvents, setUserEvents] = useState<models.EventProps[]>([]);
  const { logoutUser, user } = useAuth();
  const { t } = useTranslation();

  const currentUser = users.find((u) => u.uid === user?.uid);
  const updateUserEvents = (eventId: string, newUrl: string) => {
    setUsers((prevUsers) =>
      prevUsers.map((u) =>
        u.uid === user?.uid
          ? {
              ...u,
              userEvents: {
                ...u.userEvents,
                [eventId]: newUrl,
              },
            }
          : u
      )
    );
  };
  useEffect(() => {
    if (user && users && users.length > 0 && currentUser) {
      if (currentUser.userEvents) {
        const eventIds = Object.keys(currentUser.userEvents);
        const assignedEvents = events.filter((event) =>
          eventIds.includes(event.id)
        );
        setUserEvents(assignedEvents);
      } else {
        setUserEvents([]);
      }
    }
  }, [user, users, events, currentUser]);

  const handleCopyLink = () => {
    if (currentUser?.uid) {
      const link = `www.e-ticket.co.il/${currentUser.uid}`;
      navigator.clipboard.writeText(link);
    }
  };
  return (
    <div style={{ marginTop: "20px", marginBottom: "40px" }}>
      <Title order={1} style={{ textAlign: "center", marginBottom: "20px" }}>
        {t("helloPromoter") + currentUser?.name}
      </Title>
      <Text
        size="lg"
        style={{
          textAlign: "center",
          marginBottom: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        הקישור שלך לאתר:
        <IoCopyOutline
          size={20}
          style={{ cursor: "pointer", marginLeft: "10px" }}
          onClick={handleCopyLink}
        />
      </Text>
      <Box mt="md" mb="md">
        {userEvents.length > 0 && currentUser ? (
          <PromoterTable
            currentUser={currentUser}
            events={userEvents}
            updateUserEvents={updateUserEvents}
            setEvents={setEvents}
            deleteEndpoint={`${constants.API_BASE_URL}${constants.REMOVE_USER_EVENT_ENDPOINT}`}
          />
        ) : (
          <p style={{ textAlign: "center", fontSize: "1.1rem" }}>
            {t("noEventsAssigned")}
          </p>
        )}
      </Box>
      <Explanation />
      <Box
        style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}
      >
        <Button color="black" onClick={() => logoutUser()}>
          {t("logOutButton")}
        </Button>
      </Box>
    </div>
  );
}
