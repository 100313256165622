import { useState, useCallback } from "react";
import axios, { Method } from "axios";

interface UseApiResult<T> {
  data: T | null;
  error: string | null;
  loading: boolean;
  callApi: (method?: Method, body?: any) => void;
}

function useApi<T = any>(url: string, options?: any): UseApiResult<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    async (method: Method = "GET", body: any = null) => {
      setLoading(true);
      setError(null);
      try {
        const response = await axios({
          method,
          url,
          ...options,
          data: body || null,
        });
        console.log(response.data);
        setData(response.data);
        return response.data;
      } catch (err: any) {
        setError(err.message || "An unknown error occurred");
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [url, options]
  );

  const callApi = useCallback(
    (method: Method = "GET", body: any = null) => {
      setData(null);
      return fetchData(method, body);
    },
    [fetchData]
  );

  return { data, error, loading, callApi };
}

export default useApi;
