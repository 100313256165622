import "../../css/home/EventCube.css";
import { Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function EventCube({ event }: { event: models.EventProps }) {
  const {id} = useParams();

  return (
    <div className="event-cube-card">
      <Link to={`/event/${encodeURIComponent(event.eventTitle)}/${id}`}>
        <div className="event-cube-image-container">
          <img
            src={event.eventImage}
            alt={event.eventTitle}
            className="event-cube-image"
          />
        </div>
      </Link>
      {/* The Title is now outside the Link */}
      <Title order={3} className="event-cube-title">{event.eventTitle}</Title>
    </div>
  );
}
