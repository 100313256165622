import { Modal, Image, Title } from "@mantine/core";
import blackLogo from "../../assets/logos/blackLogo3.jpg";
import WhatsAppButton from "../../components/socialMedia/WhatsAppButton";
import "../../css/home/PopUpAd.css";

interface PopUpAdProps {
  onClose: () => void;
  opened: boolean;
}

export default function PopUpAd({ onClose, opened }: PopUpAdProps) {
  return (
    <Modal
      opened={opened}
      onClose={onClose}
      centered
      size="lg" /* You can reduce size for better mobile experience */
      withCloseButton
      padding="md" /* Reduce padding for smaller screens */
    >
      <div className="popup-ad-container">
        <Image
          src={blackLogo}
          alt="Popup Ad"
          className="popup-ad-image"
          radius="md"
        />
        <Title order={2} ta="center">
          הצטרפו לקהילת המסיבות הגדולה בישראל!
        </Title>
        <WhatsAppButton />
      </div>
    </Modal>
  );
}
