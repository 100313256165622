import { FaTrash } from "react-icons/fa";
import useApi from "../hooks/useApi";
import { v4 as uuidv4 } from "uuid";
import { useDisclosure } from "@mantine/hooks";
import { Text, Modal, Button } from "@mantine/core";

interface DeleteItemProps {
  id: string;
  setItems: React.Dispatch<React.SetStateAction<any[]>>;
  deleteEndpoint: string;
  userId: string;
}

export default function DeleteItem({
  id,
  setItems,
  deleteEndpoint,
  userId,
}: DeleteItemProps) {
  const { callApi: callApiDb } = useApi(deleteEndpoint);
  const [opened, { open, close }] = useDisclosure(false);

  const removeItem = () => {
    callApiDb("POST", { id, userId, uuid: uuidv4() });
    setItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  return (
    <div className="delete-icon-container">
      <FaTrash onClick={open} className="delete-icon" />
      <Modal opened={opened} onClose={close} centered>
        <div className="delete-modal">
          <Text fw={900} ta="center">
            למחוק את האירוע?
          </Text>
          <Button color="black" onClick={removeItem}>
            מחק
          </Button>
        </div>
      </Modal>
    </div>
  );
}
