import { useState } from "react";
import "../../css/home/EventCategorySelector.css";
import {
  eventCategories,
  timeCategories,
  areaCategories,
} from "../../utils/categories";
import { FaCalendarAlt } from "react-icons/fa";

interface CategorySelectorProps {
  onFilterChange: (filters: {
    time: string;
    event: string;
    area: string;
  }) => void;
  customTimeCategories: string[];
}

function EventCategorySelector({
  onFilterChange,
  customTimeCategories,
}: CategorySelectorProps) {
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const [selectedArea, setSelectedArea] = useState<string | null>(null);

  const updateFilters = (
    time: string | null,
    event: string | null,
    area: string | null
  ) => {
    onFilterChange({
      time: time || "",
      event: event || "",
      area: area || "",
    });
  };

  const combinedTimeCategories = [
    ...timeCategories,
    ...customTimeCategories.map((category) => ({
      value: category,
      label: category,
      icon: FaCalendarAlt,
    })),
  ];



  return (
    <div className="tags-container">
      <h2>מה המסיבה שלך?</h2>
      <div className="chip-row">
        {combinedTimeCategories.map((category) => {
          const isSelected = selectedTime === category.value;
          return (
            <button
              key={category.value}
              className={`custom-chip ${isSelected ? "selected" : ""}`}
              onClick={() => {
                const value = isSelected ? null : category.value;
                setSelectedTime(value);
                updateFilters(value, selectedEvent, selectedArea);
              }}
            >
              <category.icon className="chip-icon" />
              {category.label}
            </button>
          );
        })}
      </div>
      <div className="chip-row event-chip-row">
        {eventCategories.map((category) => {
          const isSelected = selectedEvent === category.value;
          return (
            <button
              key={category.value}
              className={`custom-chip ${isSelected ? "selected" : ""}`}
              onClick={() => {
                const value = isSelected ? null : category.value;
                setSelectedEvent(value);
                updateFilters(selectedTime, value, selectedArea);
              }}
            >
              <category.icon className="chip-icon" />
              {category.label}
            </button>
          );
        })}
      </div>
      <div className="chip-row">
        {areaCategories.map((category) => {
          const isSelected = selectedArea === category.value;
          return (
            <button
              key={category.value}
              className={`custom-chip ${isSelected ? "selected" : ""}`}
              onClick={() => {
                const value = isSelected ? null : category.value;
                setSelectedArea(value);
                updateFilters(selectedTime, selectedEvent, value);
              }}
            >
              <category.icon className="chip-icon" />
              {category.label}
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default EventCategorySelector;
