import Navbar from "../Navbar";
import Footer from "../Footer";
import { useForm, SubmitHandler } from "react-hook-form";
import { TextInput, Button, Container } from "@mantine/core";
import "../../css/workWithUs/WorkWithUs.css";
import SocialMediaSection from "../SocialMediaSection";
import useApi from "../../hooks/useApi";
import constants from "../../constants/constants";
import { ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";

interface FormValues {
  name: string;
  phone: string;
  email: string;
}

export default function WorkWithUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>();
  const { t } = useTranslation();

  const { callApi, data, loading } = useApi(
    `${constants.API_BASE_URL}${constants.FORM_SUBMISSION_ENDPOINT}`
  );
  console.log(data);
  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    callApi("POST", { formData, workWithUs: true });
    console.log("Form submitted", formData);
    reset();
  };

  return (
    <div className="work-with-us-container">
      <Navbar />
      <Container size="sm" className="form-container">
        <h1 className="form-title">{t("WorkWithUs")} </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="form-content">
          <TextInput
            label="שם"
            placeholder="הכנס את שמך"
            {...register("name", { required: "נא להשאיר שם" })}
            error={errors.name?.message}
            size="md"
            className="form-input"
          />
          <TextInput
            label="מספר טלפון"
            placeholder="הכנס את מספר הטלפון שלך"
            {...register("phone", {
              required: "נא להכניס טלפון",
              pattern: {
                value: /^\d{10}$/,
                message: "נא להכניס מספר טלפון תקין",
              },
            })}
            error={errors.phone?.message}
            size="md"
            className="form-input"
          />
          <TextInput
            label="אימייל"
            placeholder="הכנס את האימייל שלך"
            {...register("email", {
              required: "נא להכניס אימייל",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "נא להכניס אימייל תקין",
              },
            })}
            error={errors.email?.message}
            size="md"
            className="form-input"
          />
          <Button
            type="submit"
            mt="md"
            size="md"
            color="black"
            className="form-button"
          >
            שלח
          </Button>
          {loading && <ClipLoader size={30} />}
          {data && <p>הטופס נשלח בהצלחה!</p>}
        </form>
      </Container>
      <SocialMediaSection />
      <Footer />
    </div>
  );
}
