import { Table } from "@mantine/core";
import { useTranslation } from "react-i18next";
import "../css/tables/Table.css";
import EditPersonalUrl from "../components/EditPersonalUrl";
import { IoCopyOutline } from "react-icons/io5";
import DeleteItem from "../components/DeleteItem";

interface PromoterTableProps {
  currentUser: models.UserProps;
  events: models.EventProps[];
  updateUserEvents: (eventId: string, newUrl: string) => void;
  setEvents: React.Dispatch<React.SetStateAction<models.EventProps[]>>;
  deleteEndpoint: string;
}

export default function PromoterTable({
  currentUser,
  events,
  updateUserEvents,
  setEvents,
  deleteEndpoint,
}: PromoterTableProps) {
  const { t } = useTranslation();

  const copyPersonalLink = (eventTitle: string, userId: string) => {
    navigator.clipboard.writeText(
      `www.e-ticket.co.il/event/${encodeURIComponent(eventTitle)}/${userId}`
    );
  };

  const rows = events?.map((element: models.EventProps, index: number) => {
    const isMissingLink = currentUser.userEvents[element.id] === "";

    return (
      <Table.Tr
        key={element.id}
        style={isMissingLink ? { backgroundColor: "rgba(255, 0, 0, 1)" } : {}}
      >
        <Table.Td>{index + 1}</Table.Td>
        <Table.Td>{element.eventTitle}</Table.Td>
        <Table.Td>{element.eventDate}</Table.Td>
        <Table.Td>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <EditPersonalUrl
              currentUser={currentUser}
              eventId={element.id}
              updateUserEvents={updateUserEvents}
            />
            <DeleteItem
              id={element.id}
              setItems={setEvents}
              deleteEndpoint={deleteEndpoint}
              userId={currentUser.uid}
            />
            {isMissingLink ? (
              <span></span>
            ) : (
              <IoCopyOutline
                size={20}
                style={{ cursor: "pointer" }}
                onClick={() => copyPersonalLink(element.eventTitle, currentUser.uid)}
              />
            )}
          </div>
        </Table.Td>
      </Table.Tr>
    );
  });

  return (
    <>
      <div
        className="events-table-container"
        style={{
          border: "1px solid #ccc",
          borderRadius: "5px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
        }}
      >
        <Table striped withColumnBorders className="events-table">
          <Table.Thead>
            <Table.Tr>
              <Table.Th>{t("columnNumber")}</Table.Th>
              <Table.Th>{t("columnEvent")}</Table.Th>
              <Table.Th>{t("columnEventDate")}</Table.Th>
              <Table.Th>{t("setting")}</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </div>
    </>
  );
}
