import EventCubeList from "../../components/EventCubeList";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import { Title } from "@mantine/core";
import { useParams } from "react-router-dom";
import Footer from "../Footer";
import Navbar from "../Navbar";
import ReactWhatsapp from "react-whatsapp";
import { FaWhatsapp } from "react-icons/fa";
import SocialMediaSection from "../SocialMediaSection";
import constants from "../../constants/constants";

export default function Search() {
  const { events, loading } = useDataFetcher();
  const { searchValue = "" } = useParams();
  const lowercasedSearchValue = searchValue.toLowerCase();

  const filteredEvents = events.filter((event) => {
    const eventTitle = event.eventTitle.toLowerCase();
    const eventDescription = event.eventDescription.toLowerCase();
    const eventTags = event.tags.map((tag) => tag.toLowerCase());

    return (
      eventTitle.includes(lowercasedSearchValue) ||
      eventDescription.includes(lowercasedSearchValue) ||
      eventTags.some((tag) => tag.includes(lowercasedSearchValue))
    );
  });

  return (
    <div>
      <Navbar />
      <Title order={1}>תוצאות החיפוש ל: {searchValue}</Title>
      <EventCubeList events={filteredEvents} loading={loading} />
      <SocialMediaSection />
      <ReactWhatsapp
        element="button"
        number={constants.SHAHAR_NUMBER}
        message="היי הגעתי מאתר ENTER אשמח לקבל קוד הטבה לאירוע .."
        className="whatsapp-button"
      >
        <FaWhatsapp size="38px" />
      </ReactWhatsapp>
      <Footer />
    </div>
  );
}
