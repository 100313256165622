import Login from "../pages/Login";
import Admin from "../pages/dashboard/Dashboard";
import PrivateRoutes from "../components/PrivateRoutes";
import Home from "../pages/home/Home";
import EventPage from "../pages/event/EventPage";
import Search from "../pages/search/Search";
import WorkWithUs from "../pages/workWithUs/WorkWithUs";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import AccessibilityStatement from "../pages/accessibilityStatement/AccessibilityStatement";
import Register from "../pages/Register";
import ForgotPassword from "../pages/ForgotPassword";
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
} from "react-router-dom";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      {/* Home Route with optional ID parameter */}
      <Route index element={<Home />} />
      <Route path=":id?" element={<Home />} />

      <Route path="login/:id?" element={<Login />} />
      <Route path="register/:id?" element={<Register />} />

      {/* EventPage Route with optional ID parameter */}
      <Route path="event/:eventTitle/:id?" element={<EventPage />} />

      {/* Search Route with optional ID parameter */}
      <Route path="search/:searchValue/:id?" element={<Search />} />

      <Route path="work-with-us/:id?" element={<WorkWithUs />} />

      <Route path="privacy-policy/:id?" element={<PrivacyPolicy />} />

      <Route
        path="accessibility-statement/:id?"
        element={<AccessibilityStatement />}
      />
      <Route path="forgot-Password" element={<ForgotPassword />} />

      <Route element={<PrivateRoutes />}>
        <Route path="admin/:id?" element={<Admin />} />
      </Route>
    </Route>
  )
);

export default router;
