import { useState } from "react";
import { BsTagsFill } from "react-icons/bs";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, TextInput, Badge } from "@mantine/core";
import useApi from "../hooks/useApi";
import constants from "../constants/constants";
import { v4 as uuidv4 } from "uuid";
import "../css/EventTags.css";

interface EventTagsProps {
  event: models.EventProps;
  setEvents: React.Dispatch<React.SetStateAction<models.EventProps[]>>;
  events: models.EventProps[];
}

export default function EventTags({
  event,
  setEvents,
  events,
}: EventTagsProps) {
  const [opened, { open, close }] = useDisclosure(false);
  const [tagValue, setTagValue] = useState<string>("");

  const { callApi: callApiAddTag } = useApi(
    `${constants.API_BASE_URL}${constants.ADD_TAG_ENDPOINT}`
  );

  const { callApi: callApiDeleteTag } = useApi(
    `${constants.API_BASE_URL}${constants.DELETE_TAG_ENDPOINT}`
  );

  const handleTags = async () => {
    try {
      if (event.tags.includes(tagValue.trim())) {
        alert("התגית כבר קיימת באירוע");
        return; // Prevent further action if the tag exists
      }
      callApiAddTag("POST", {
        tag: tagValue,
        eventId: event.id,
        uuid: uuidv4(),
      });

      const updatedEvents = events.map((ev) => {
        if (ev.id === event.id) {
          return { ...ev, tags: [...ev.tags, tagValue] };
        }
        return ev;
      });

      setEvents(updatedEvents);
      setTagValue("");
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };

  const deleteChip = async (tag: string) => {
    try {
      await callApiDeleteTag("POST", {
        tag,
        eventId: event.id,
        uuid: uuidv4(),
      });

      const updatedEvents = events.map((ev) => {
        if (ev.id === event.id) {
          return { ...ev, tags: ev.tags.filter((t) => t !== tag) };
        }
        return ev;
      });

      setEvents(updatedEvents);
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  return (
    <div>
      <BsTagsFill onClick={open} className="tag-icon-container" />
      <Modal opened={opened} onClose={close} title="תגיות" centered>
        <div className="modal-content">
          <TextInput
            placeholder="הוסף תגית"
            value={tagValue}
            onChange={(event) => setTagValue(event.target.value)}
            className="tag-input"
          />
          <Button onClick={handleTags} className="add-tag-button">
            הוסף
          </Button>
        </div>

        <div className="badges-container">
          {event.tags?.map((tag: string) => (
            <Badge
              key={tag}
              className="tag-badge"
              onClick={() => deleteChip(tag)}
            >
              {tag}
            </Badge>
          ))}
        </div>
      </Modal>
    </div>
  );
}
