import "@mantine/core/styles.css";
import "./App.css";
import router from "./routes/routes";
import { MantineProvider } from "@mantine/core";
import { AuthProvider } from "./utils/AuthContext";
import { RouterProvider } from "react-router-dom";
import "./css/Global.css";

function App() {
  return (
    <MantineProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </MantineProvider>
  );
}

export default App;
