import { useParams } from "react-router-dom";
import "../../css/event/EventPage.css";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import parse from "html-react-parser";
import blackLogo from "../../assets/logos/blackLogo.png";
import StickyFooter from "./StickyFooter";
import WhatsAppButton from "../../components/socialMedia/WhatsAppButton";
import FacebookButton from "../../components/socialMedia/FacebookButton";
import InstagramButton from "../../components/socialMedia/InstagramButton";
import DiscountBanner from "./../event/DiscountBanner";
import OrderTicketsButton from "./OrderTicketsButton";
import EventCubeList from "../../components/EventCubeList";
import { Title } from "@mantine/core";
import { ClipLoader } from "react-spinners";
import { findPersonalUrl } from "../../utils/findPersonalUrl";

function EventPage() {
  const { eventTitle, id } = useParams<{ eventTitle: string; id?: string }>();
  const { t } = useTranslation();

  const { events, users, loading } = useDataFetcher();

  const event = events.find((event: { eventTitle: string | undefined; }) => event.eventTitle === eventTitle);

  if (!event) {
    return null;
  }

  const personalUrl = findPersonalUrl(event, users, id);

  const parsedDescription = event?.eventDescription
    ? parse(event.eventDescription)
    : "";

  return (
    <>
      <Navbar />
      {loading ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={50} />
        </div>
      ) : (
        <>
          <h1 className="event-title-top">{event?.eventTitle}</h1>
          <div className="event-page-container">
            <div className="event-page-card">
              <div className="event-page-image-container">
                <img
                  src={event?.eventImage}
                  alt={event?.eventTitle}
                  className="event-page-image"
                />
                <WhatsAppButton />
                <InstagramButton />
                <FacebookButton />
              </div>

              <div className="event-details">
                <div className="event-info">
                  <p className="event-date">תאריך: {event?.eventDate}</p>
                  <p className="event-location">
                    מיקום: {event?.eventLocation}
                  </p>
                </div>
                {event?.eventUrl && (
                  <OrderTicketsButton eventUrl={personalUrl} />
                )}
                {event?.eventImage && (
                  <DiscountBanner imageUrl={event.eventImage} />
                )}
                <div className="event-description">{parsedDescription}</div>
                {event?.eventUrl && (
                  <OrderTicketsButton eventUrl={personalUrl} />
                )}
              </div>
            </div>
          </div>
          <div className="additional-events-container">
            <Title order={2} className="additional-events-title">
              עוד אירועים שלנו!
            </Title>
          </div>
          <EventCubeList events={events} loading={loading} />
        </>
      )}
      {event && <StickyFooter event={event} eventUrl={personalUrl} />}
      <Footer />
    </>
  );
}
export default EventPage;
