// AuthContext.tsx

import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import { auth } from "./firebase";
import {
  User,
  signInWithEmailAndPassword,
  signOut,
  setPersistence,
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useTranslation } from "react-i18next";
import blackLogo from "../assets/logos/blackLogo.png";
import { ClipLoader } from "react-spinners";
import useApi from "../hooks/useApi";
import constants from "../constants/constants";

interface AuthContextType {
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  userRole: string | null;
  loginUser: (
    email: string | undefined,
    password: string | undefined
  ) => Promise<void>;
  logoutUser: () => Promise<void>;
  registerUser: (data: RegisterData) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  authError: string | null;
  getIdToken: () => Promise<string | null>;
  setAuthError: (error: string | null) => void;
}

interface RegisterData {
  email: string;
  password: string;
  name: string;
  phoneNumber: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [loading, setLoading] = useState<Boolean>(true);
  const [user, setUser] = useState<User | null>(null);
  const [authError, setAuthError] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const { t } = useTranslation();
  const { callApi: registerUserApi } = useApi(
    `${constants.API_BASE_URL}${constants.ADD_USER_ENDPOINT}`
  );

  // Function to get user role
  const getUserRole = async (user: User): Promise<string | null> => {
    const idTokenResult = await user.getIdTokenResult(true); // Force refresh
    return (idTokenResult.claims.role as string) || null;
  };

  // Check user status on component mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        const role = await getUserRole(currentUser);
        setUserRole(role);
      } else {
        setUserRole(null);
      }
      setLoading(false);
    });

    // Initial check
    checkUserStatus();

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUserStatus = async () => {
    try {
      await setPersistence(auth, browserLocalPersistence);
      const currentUser = auth.currentUser;
      setUser(currentUser);
      if (currentUser) {
        const role = await getUserRole(currentUser);
        setUserRole(role);
      } else {
        setUserRole(null);
      }
    } catch (error: any) {
      console.log(error);
    }
    setLoading(false);
  };

  const loginUser = async (
    email: string | undefined,
    password: string | undefined
  ) => {
    setLoading(true);
    try {
      if (!email || !password) return;
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const currentUser = userCredential.user;

      if (!currentUser.emailVerified) {
        await signOut(auth);
        setAuthError(t("emailNotVerified"));

        setTimeout(() => {
          setAuthError(null);
        }, 3000);

        return;
      }

      setUser(userCredential.user);
      const role = await getUserRole(userCredential.user);
      setUserRole(role);
    } catch (error: any) {
      console.log(error.code);
      if (error.code === "auth/invalid-credential") {
        setAuthError(t("userNotFound"));
      } else if (error.code === "auth/invalid-email") {
        setAuthError(t("invalidEmail"));
      } else {
        setAuthError(t("temporaryError"));
      }
      setTimeout(() => {
        setAuthError(null);
      }, 3000);
    }
    setLoading(false);
  };

  const logoutUser = async () => {
    try {
      await signOut(auth);
      setUser(null);
      setUserRole(null);
    } catch (error: any) {
      console.error(error);
    }
  };

  const registerUser = async (data: RegisterData) => {
    setLoading(true);
    setAuthError(null);
    try {
      console.log(data.email, data.password);
      if (!data.email || !data.password) return;
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      await signOut(auth);
      alert("נרשמת בהצלחה, על מנת להפעיל את החשבון יש לאשר דרך האימייל שנשלח");
      await sendEmailVerification(userCredential.user);

      const idToken = await userCredential.user.getIdToken();

      registerUserApi("POST", { authorization: idToken, data });
      await userCredential.user.getIdToken(true);
      const role = await getUserRole(userCredential.user);
      setUserRole(role);
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use") {
        setAuthError("שם המשתמש קיים במערכת");
      } else if (error.code === "auth/invalid-email") {
        setAuthError("אימייל לא חוקי");
      } else {
        setAuthError("תקלה זמנית, נא לנסות עוד כמה דקות.");
      }
      setTimeout(() => {
        setAuthError(null);
      }, 3000);

      console.error("Registration error:", error);
    }
    setLoading(false);
  };

  const resetPassword = async (email: string) => {
    setLoading(true);
    setAuthError(null);
    try {
      await sendPasswordResetEmail(auth, email);
      alert("קישור לאיפוס הסיסמה נשלח לאימייל שלך.");
    } catch (error: any) {
      console.error("Error sending password reset email:", error);
      if (error.code === "auth/user-not-found") {
        setAuthError("משתמש עם האימייל הזה לא נמצא.");
      } else if (error.code === "auth/invalid-email") {
        setAuthError("אימייל לא חוקי.");
      } else {
        setAuthError("תקלה זמנית, נא לנסות שוב מאוחר יותר.");
      }
      // Clear the error after 4 seconds
      setTimeout(() => {
        setAuthError(null);
      }, 4000);
    }
    setLoading(false);
  };

  const getIdToken = async (): Promise<string | null> => {
    if (user) {
      try {
        const token = await user.getIdToken(/* forceRefresh */ true);
        return token;
      } catch (error) {
        console.error("Error getting ID token:", error);
        return null;
      }
    } else {
      return null;
    }
  };

  const contextData = {
    user,
    setUser,
    userRole,
    loginUser,
    logoutUser,
    registerUser,
    authError,
    setAuthError,
    getIdToken,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={80} />
        </div>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthContext;
