import { IconX, IconCheck } from "@tabler/icons-react";
import { Notification, rem } from "@mantine/core";

interface AlertProps {
  alertData: {
    title: string;
    message: string;
    color: string;
    icon: boolean;
  };
}

export default function Alert({ alertData }: AlertProps) {
  const xIcon = <IconX style={{ width: rem(20), height: rem(20) }} />;
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  return (
    <Notification
      icon={alertData.icon ? checkIcon : xIcon}
      color={alertData.color}
      title={alertData.title}
    >
      {alertData.message}
    </Notification>
  );
}
