import { Link } from "react-router-dom";
import "../../css/event/OrderTicketsButton.css";
import { IoTicketSharp } from "react-icons/io5";

interface OrderTicketsButtonProps {
  eventUrl: string;
}

export default function OrderTicketsButton({
  eventUrl
}: OrderTicketsButtonProps) {

  return (
    <div className="purchase-button-container">
      <Link to={eventUrl}>
        <button className="purchase-button">
          <IoTicketSharp />
          להזמנת כרטיסים
        </button>
      </Link>
    </div>
  );
}
