import { Button } from "@mantine/core";
import { useAuth } from "../../utils/AuthContext";
import { useTranslation } from "react-i18next";
import { useDataFetcher } from "../../hooks/useDataFetcher";
import blackLogo from "../../assets/logos/blackLogo.png";
import { ClipLoader } from "react-spinners";
import PromotersView from "./promoters/PromotersView";
import "../../css/admin/Dashboard.css";
import AdminView from "./admin/AdminView";

function Admin() {
  const { logoutUser, userRole } = useAuth();
  const { t } = useTranslation();

  const {
    events,
    setEvents,
    users,
    setUsers,
    utils,
    callApiDb,
    loading: loadingDb,
  } = useDataFetcher();

  return (
    <div className="admin-container">
      {loadingDb ? (
        <div className="loading-container">
          <img src={blackLogo} alt={t("loading")} className="loading-logo" />
          <ClipLoader size={50} />
        </div>
      ) : userRole === "admin" ? (
        <AdminView
          events={events}
          setEvents={setEvents}
          users={users}
          setUsers={setUsers}
          utils={utils}
          callApiDb={callApiDb}
          loadingDb={loadingDb}
        />
      ) : userRole === "editor" ? (
        <PromotersView
          events={events}
          users={users}
          setUsers={setUsers}
          setEvents={setEvents}
        />
      ) : (
        <div className="logout-button">
          <Button color="black" onClick={() => logoutUser()}>
            {t("logOutButton")}
          </Button>
        </div>
      )}
    </div>
  );
}

export default Admin;
