import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  he: {
    translation: {
      setting: "הגדרות",
      addEventsTitle: "הוספת אירוע",
      addEventLabel: "אירוע חדש",
      addEventPlaceholder: "נא הכנס את קישור האירוע כדי להוסיף אותו למערכת",
      addEventButton: "הוסף אירוע",
      loading: "בטעינה....",
      logOutButton: "התנתק",
      loginPageTitle: "התחברות",
      personalLink: "קישור אישי",
      regiterPageTitle: "הרשמה כיחצן",
      nameLabel: "שם מלא",
      namePlaceholder: "נא הכנס שם מלא",
      nameRequired: "נא להכניס שם מלא",
      phoneNumberLabel: "מספר טלפון",
      phoneNumberPlaceholder: "נא הכנס מספר טלפון",
      phoneNumberRequired: "נא להכניס מספר טלפון",
      phoneNumberInvalid: "מספר טלפון לא חוקי",
      emailNotVerified: "יש לאשר את החשבון בהודעה שנשלחה במייל",
      emailLabel: "אימייל",
      forgotPasswordButton: "שכחת סיסמא?",
      forgotPasswordTitle: "איפוס סיסמה",
      resetPasswordButton: "שלח מייל לאיפוס סיסמה",
      passwordLabel: "סיסמא",
      verifyPasswordLabel: "אימות סיסמא",
      emailPlaceholder: "נא הכנס אימייל",
      passwordPlaceholder: "נא הכנס סיסמא",
      loginButton: "כניסה",
      registerButton: "הרשמה",
      moveToRegisterPage: "הירשם כיחצן",
      userNotFound: "שם משתמש או סיסמא לא נכונים",
      wrongPassword: "סיסמה שגויה",
      invalidEmail: "אימייל לא חוקי",
      temporaryError: "תקלה זמנית, נא לנסות עוד כמה דקות.",
      moveToLoginPage: "כבר רשום?",
      addNewEmployee: "הוסף יחצן",
      emailPattern: "נא הכנס כתובת דואר אלקטרוני תקינה",
      emailRequired: "נא להכניס אימייל",
      passwordRequired: "נא להכניס סיסמא",
      passwordMinLength: "סיסמא חייבת להיות לפחות 8 תווים",
      passwordPattern: "סיסמא חייבת להכיל ספרות, אותיות קטנות וגדולות",
      passwordsDoNotMatch: "סיסמא לא תואמת",
      nameNewEmployeeLabel: "שם מלא",
      nameNewEmployeePlaceholder: "נא הכנס שם מלא",
      idNewEmployeeEventerLabel: "מספר מזהה eventer",
      idNewEmployeeGoOutLabel: "מספר מזהה go out",
      idNewEmployeeZygoLabel: "מספר מזהה zygo",
      idNewEmployeePlaceholder: "נא הכנס מספר מזהה",
      phoneNewEmployeeLabel: "מספר טלפון",
      PhoneNewEmployeePlaceholder: "נא הכנס מספר טלפון",
      eventAlreadyExistTitle: "האירוע כבר קיים!",
      eventAlreadyExist: "האירוע שניסית להוסיף כבר קיים במערכת",
      eventAddedTitle: "האירוע נוסף בהצלחה!",
      eventAddedMessage: "האירוע שסרקת נוסף לאתר ולטבלה למטה",
      problemWithScraperTitle: "תקלה",
      problemWithScraperMessage:
        "המערכת לא הצליחה לסרוק את האתר, נא בדוק את כתובת האתר שרשמת ונסה שוב",
      columnNumber: "מס'",
      columnEvent: "שם אירוע",
      columnEventDate: "תאריך",
      columnEventLocation: "מיקום",
      columnEmployeeName: "שם יחצן",
      columnEmployeeIdEventer: "מזהה אוונטר",
      columnEmployeeIdGoOut: "מזהה גו-אאוט",
      columnEmployeeIdZygo: "מזהה זיגו",
      columnEmployeePhone: "טלפון",
      homeButton: "דף הבית",
      accessibilityStatement: "הצהרת נגישות",
      contactUs: "רוצים לעבוד איתנו?",
      PRIVACY_POLICY_URL: "מדיניות פרטיות",
      searchEvent: "חיפוש אירוע",
      eventsTab: "אירועים",
      employeesTab: "יחצנים",
      refreshEventsButton: "שמור שינויים",
      carouselTitle: "קרוסלה",
      addcarousel: "הוסף אירוע לקרוסלה",
      saveCarousel: "שמור קרוסלה",
      facebookButton: "הצטרפו אלינו בפייסבוק",
      instagramButton: "הצטרפו אלינו באינסטגרם",
      whatsappButton: "הצטרפו אלינו בוואטסאפ",
      other: "אחר",
      loadMoreEvents: "טען עוד אירועים.. 🎉",
      search: "חיפוש",
      searchPlaceholder: "חפשו אומן, מסיבה או לוקיישן...",
      locationInTable: "מיקום",
      saveTableChanges: "שמור שינויים בטבלה",
      addCategoriesTitle: "קטגוריות",
      WorkWithUs: "רוצים לעבוד איתנו?",
      saveUsersButton: "שמור  יחצנים",
      saveCustomUrl: "שמור קישור מותאם",
      helloPromoter: "שלום ",
      noEventsAssigned: "אין כרגע אירועים שאתה מקושר אליהם",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "he",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
