import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import constants from "../constants/constants";
import "../css/Navbar.css";
import whiteLogo from "../assets/logos/whiteLogo.png";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { TextInput, Button } from "@mantine/core";
import { useParams } from "react-router-dom";

export default function Navbar() {
  const { t } = useTranslation();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const handleSearch = () => {
    if (searchValue.trim() === "") return;
    console.log("Searching for:", searchValue);
    navigate(`/search/${encodeURIComponent(searchValue)}/${id}`);
  };

  return (
    <nav className="navbar">
      <div className="navbar-right">
        <Link to={`${constants.WORK_WITH_US_URL}/${id}`} className="navbar-link">
        {t("contactUs")}
        </Link>
      </div>

      <div className="navbar-center">
        <Link to={`${constants.HOME_URL}${id}`} className="navbar-brand">
          <img src={whiteLogo} alt="logo" />
        </Link>
      </div>

      <div className="navbar-left">
        <div className="navbar-link" onClick={() => setOpenSearch(!openSearch)}>
          <FaMagnifyingGlass />
          {t("searchEvent")}
        </div>

        {openSearch && (
          <div className="navbar-search-container">
            <TextInput
              className="navbar-search-input"
              value={searchValue}
              onChange={(event) => setSearchValue(event.currentTarget.value)}
            />
            <Button className="navbar-search-button" onClick={handleSearch}>
              {t("searchEvent")}
            </Button>
          </div>
        )}
      </div>
    </nav>
  );
}
