import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import 'dayjs/locale/en-gb';

interface DateSelectorProps {
  date: Dayjs | null;
  setDate: (date: Dayjs | null) => void;
}

export default function DateSelector({ date, setDate }: DateSelectorProps) {
  return (
    <div>
      <h3>תאריך המסיבה</h3>
      <div
        style={{ display: "flex", justifyContent: "center" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <DatePicker
            label="בחר תאריך של המסיבה"
            value={date}
            onChange={(newValue) => setDate(newValue)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
