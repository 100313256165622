import "../css/Login.css";
import { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import { Button, Title } from "@mantine/core";
import { TextInput, PasswordInput } from "@mantine/core";
import { useForm, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
// import useApi from "../hooks/useApi";
// import constants from "../constants/constants";

interface FormValues {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
  confirmPassword: string;
}

export default function Register() {
  const registerForm = useRef<HTMLFormElement | null>(null);
  const navigate = useNavigate();
  const { registerUser, user, authError, setAuthError } = useAuth();
  const methods = useForm<FormValues>();
  const { register, handleSubmit, formState } = methods;
  const { t } = useTranslation();

  useEffect(() => {
    if (user) {
      navigate("/login");
    }
  }, [user, navigate]);

  console.log(user);
  const onSubmit = async (data: FormValues) => {
    try {
      await registerUser(data);
    } catch (error: any) {
      console.error("Registration failed:", error.message);
    }
  };

  const moveToLoginPage = () => {
    navigate("/login");
    setAuthError(null);
  };

  return (
    <div className="login-container">
      <FormProvider {...methods}>
        <form
          ref={registerForm}
          onSubmit={handleSubmit(onSubmit)}
          className="login-form-container"
        >
          <Title>{t("regiterPageTitle")}</Title>
          <TextInput
            label={t("nameLabel")}
            placeholder={t("namePlaceholder")}
            required
            mt="md"
            {...register("name", {
              required: t("nameRequired"),
            })}
            error={formState.errors.name?.message}
          />
          <TextInput
            label={t("phoneNumberLabel")}
            placeholder={t("phoneNumberPlaceholder")}
            required
            mt="md"
            {...register("phoneNumber", {
              required: t("phoneNumberRequired"),
              pattern: {
                value: /^[0-9]{10}$/,
                message: t("phoneNumberInvalid"),
              },
            })}
            error={formState.errors.phoneNumber?.message}
          />
          <TextInput
            label={t("emailLabel")}
            placeholder={t("emailPlaceholder")}
            required
            mt="md"
            {...register("email", {
              required: t("emailRequired"),
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: t("emailPattern"),
              },
            })}
            error={formState.errors.email?.message}
          />
          <PasswordInput
            label={t("passwordLabel")}
            placeholder={t("passwordPlaceholder")}
            required
            mt="md"
            {...register("password", {
              required: t("passwordRequired"),
              minLength: {
                value: 8,
                message: t("passwordMinLength"),
              },
              pattern: {
                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                message: t("passwordPattern"),
              },
            })}
            error={formState.errors.password?.message}
          />
          <PasswordInput
            label={t("verifyPasswordLabel")}
            placeholder={t("passwordPlaceholder")}
            required
            mt="md"
            {...register("confirmPassword", {
              required: t("passwordRequired"),
              validate: (value) =>
                value === methods.getValues("password") ||
                t("passwordsDoNotMatch"),
            })}
            error={formState.errors.confirmPassword?.message}
          />
          <div>
            {authError && (
              <div style={{ color: "red", marginTop: "10px" }}>{authError}</div>
            )}
            <Button style={{ marginTop: "10px" }} type="submit">
              {t("registerButton")}
            </Button>
            <Button style={{ marginTop: "10px" }} onClick={moveToLoginPage}>
              {t("moveToLoginPage")}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
