import "../css/Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import constants from "../constants/constants";
import { useParams } from "react-router-dom";

export default function Footer() {
  const { t } = useTranslation();
  const { id } = useParams();

  return (
    <footer className="footer">
      <div className="footer-content">
        <Link to={`${constants.HOME_URL}${id}`} className="footer-brand">
          {t("homeButton")}
        </Link>
        <ul className="footer-menu">
          <li className="footer-item">
            <Link
              to={`${constants.ACCESSIBILITY_STATEMENT_URL}/${id}`}
              className="footer-link"
            >
              {t("accessibilityStatement")}
            </Link>
          </li>
          <li className="footer-item">
            <Link
              to={`${constants.WORK_WITH_US_URL}/${id}`}
              className="footer-link"
            >
              {t("contactUs")}
            </Link>
          </li>
          <li className="footer-item">
            <Link
              to={`${constants.PRIVACY_POLICY_URL}/${id}`}
              className="footer-link"
            >
              {t("PRIVACY_POLICY_URL")}
            </Link>
          </li>
          <li className="footer-item">
            <Link
              to={`${constants.LOGIN_URL}/${id}`}
              className="footer-link"
            >
              {t("loginPageTitle")}
            </Link>
          </li>
        </ul>
      </div>

      {/* Disclaimer section */}
      <div className="footer-disclaimer">
        <p>
          enter-ticketss אינו משרד כרטיסים. האתר מספק קישורים לאתרים חיצוניים
          אשר הינם האחרים הבלעדיים על המוצר, התוכן והתשלום. אנו ממליצים על
          אירועים על פי ראות דעתנו בלבד.
        </p>
      </div>
    </footer>
  );
}
