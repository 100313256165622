import { Button } from "@mantine/core";
import { Link } from "react-router-dom";
import "../../css/event/StickyFooter.css";
import { useStickyFooter } from "../../hooks/useStickyFooter";

interface StickyFooterProps {
  event: models.EventProps;
  eventUrl: string;
}

export default function StickyFooter({ event, eventUrl }: StickyFooterProps) {
  const { isSticky, isAboveFooter } = useStickyFooter();

  if (!isSticky) {
    return null;
  }

  return (
    <div
      className={`sticky-footer ${
        isAboveFooter ? "sticky" : "absolute"
      } visible`}
    >
      <h2>{event.eventTitle}</h2>
      <h3>{event.eventDate}</h3>
      <Link to={eventUrl} style={{ textDecoration: "none" }}>
        <Button variant="filled" color="black" size="xl" radius="lg">
          רכישת כרטיסים
        </Button>
      </Link>
    </div>
  );
}
